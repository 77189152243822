<template>
  <div class="user-data-field">
    <p class="title">{{ fieldTitle }}</p>
    <div class="data-field">
      <div
        v-if="statusBadge"
        class="status-badge"
        :style="statusBadge ? 'background-color: ' + statusBadge.color : ''"
      ></div>
      <p class="data">{{ userData }}</p>
      <slot name="right-content"/>
    </div>

    <slot name="content" />
  </div>
</template>

<script>
export default {
  name: 'UserDataField',
  props: {
    fieldTitle: {
      type: String,
      required: true
    },
    userData: {
      type: [String, Number],
      required: true
    },
    statusBadge: {
      type: Object,
      required: false,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/base.variables';
@import '~@/assets/css/base.mixins';

.user-data-field {
  background-color: white;
  border-radius: rem(20);
  padding: rem(17) rem(24);
  position: relative;

  .title {
    font-size: rem(14);
    letter-spacing: 0;
    line-height: rem(17);
    margin-bottom: rem(1);
    opacity: 0.6;
    text-transform: uppercase;
  }

  .data-field {
    display: flex;
    align-items: center;

    .status-badge {
      height: 8px;
      width: 8px;
      margin-right: 10px;
      border-radius: 50%;
    }

    .data {
      font-size: rem(20);
      font-weight: 600;
      letter-spacing: 0;
      line-height: rem(24);
    }
  }
}
</style>
