<template>
  <div class="content-container">
    <PageTitle :title="$t('SETTINGS.MY_ACCOUNT')" class="page-title"/>

    <!-- User Details/Data Section -->
    <h1 class="page-section-title section-title">{{ $t('SETTINGS.DETAILS') }}</h1>
    <div class="user-data-box">
      <UserDataField :field-title="$t('EMAIL_ADDRESS')" :user-data="emailAddress" class="data-item"/>
      <UserDataField :field-title="$t('PASSWORD')" :user-data="'**********'" class="data-item"/>
      <BasicButton :button-text="$t('EDIT_DATA')" class="button-user-data" @click="redirectToEdit()"/>
    </div>

    <!-- Newsletter Switches -->
    <h1 class="page-section-title section-title">{{ $t('NEWSLETTER') }}</h1>
    <p class="page-section-text section-text">{{ $t('SETTINGS.NEWSLETTER_TEXT') }}</p>
    <div class="newsletter-options">
      <!-- TODO: We could also v-for this. Make sure to pass the correct function (if needed at all of course). For now, they all have the same. -->
      <SwitchToggle :value="mediaMastersNewsletters"
                    class="option"
                    :text="$t('SETTINGS.MM_NEWSLETTER')"
                    :bind-value="true"
                    :on-value-update="(value) => preUpdate({
                      store: EDIT_RECEIVE_GENERAL_NEWSLETTER,
                      value : value
                    })"/>
      <SwitchToggle :value="receiveMediaWiseNewsletters"
                    class="option"
                    :text="$t('SETTINGS.MEDIA_WISE_NEWSLETTER')"
                    :bind-value="true"
                    :on-value-update="(value) => preUpdate({
                      store: EDIT_RECEIVE_NEWSLETTER_WEEK_MEDIA,
                      value : value
                    })"/>
    </div>

    <!-- Change Preference Warning-->
    <BasicModal/>

    <!-- TODO: Add the toast on 10.0.4 - Password changed. -->
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import SwitchToggle from '@/components/elements/SwitchToggle'
import { ROUTE_NAMES_TEACHER_PORTAL } from '@/router/modules/teacherPortal'
import { useRouter } from 'vue-router'
import UserDataField from '@/components/elements/settings/UserDataField'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { RESET_BASIC_MODAL_DATA, SET_BASIC_MODAL_DATA } from '@/store/mutations'
import {
  GET_TEACHER_SETTINGS,
  EDIT_RECEIVE_GENERAL_NEWSLETTER,
  EDIT_RECEIVE_NEWSLETTER_WEEK_MEDIA
} from '@/store/modules/teacherPortal/actions'
import BasicButton from '@/components/elements/basic/BasicButton'
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'
import { getCorrectLink, PAGES } from '@/utils/helpers/RouterSelector'

export default {
  name: 'Settings',
  components: {
    BasicModal,
    UserDataField,
    SwitchToggle,
    PageTitle,
    BasicButton
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    getTeacherSettings()
    const settings = computed(() => store.getters.getTeacherSettings)

    const emailAddress = computed(() => settings.value.email ?? '')
    const mediaMastersNewsletters = computed(() => settings.value ? settings.value.receiveGeneralNewsletter : false)
    const receiveMediaWiseNewsletters = computed(() => settings.value ? settings.value.receiveNewsletterWeekMedia : false)

    function getTeacherSettings() {
      return store.dispatch(GET_TEACHER_SETTINGS)
    }

    /** User Details/Data Section **/
    function redirectToEdit() {
      router.push({ name: getCorrectLink(PAGES.EDIT_DETAILS) })
    }

    function preUpdate(newPreference) {
      if (newPreference.value) {
        updatePreference(newPreference)
        return
      }
      //warning popup if came before
      if (newPreference.store === EDIT_RECEIVE_GENERAL_NEWSLETTER) {
        displayUpdateWarning(BASIC_MODAL_CONTENT.CHANGE_MEDIA_MASTERS_NEWSLETTER_PREFERENCE, reversePreference, updatePreference, MODAL_NAMES.CHANGE_MEDIA_MASTERS_NEWSLETTER_PREFERENCE, newPreference)
      } else if (newPreference.store === EDIT_RECEIVE_NEWSLETTER_WEEK_MEDIA) {
        displayUpdateWarning(BASIC_MODAL_CONTENT.MEDIA_WISE_NEWSLETTER, reversePreference, updatePreference, MODAL_NAMES.MEDIA_WISE_NEWSLETTER, newPreference)
      }
    }


    function updatePreference(preference) {
      store.dispatch(preference.store, preference.value)
      store.commit(RESET_BASIC_MODAL_DATA)
    }

    function reversePreference(data) {
      store.commit(RESET_BASIC_MODAL_DATA)
    }

    function displayUpdateWarning(modalContent, cancelCallback, executeCallback, modalName, data) {
      const payload = {
        modalOptions: {
          modalContent: modalContent,
          cancelCallback: cancelCallback,
          executeCallback: executeCallback,
          data: data
        },
        activeModal: modalName
      }
      store.commit(SET_BASIC_MODAL_DATA, payload)
    }

    return {
      preUpdate,
      mediaMastersNewsletters,
      receiveMediaWiseNewsletters,

      EDIT_RECEIVE_GENERAL_NEWSLETTER,
      EDIT_RECEIVE_NEWSLETTER_WEEK_MEDIA,

      /** User Details/Data Section **/
      redirectToEdit,
      emailAddress
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.section-text {
  margin-bottom: rem(32);
}

.section-title {
  margin-bottom: rem(16);
}

/** User data **/
.user-data-box {
  margin-bottom: rem(40);
  max-width: rem(746);

  .data-item {
    &:not(:last-of-type) {
      margin-bottom: rem(16);
    }
  }

  .button-user-data {
    margin-top: rem(40);
  }
}

/** **/

/** Newsletter Switches **/
.newsletter-options {
  .option {
    &:not(:last-of-type) {
      margin-bottom: rem(24);
    }
  }
}

/** **/
</style>
