<template>
  <div class="switch-box">
    <div class="switch-toggle">
      <label class="switch">
        <input v-if="bindValue" type="checkbox" :oninput="onSwitchInput" :checked="value">
        <input v-model="switchValue" type="checkbox" :oninput="onSwitchInput">
        <span class="slider round"></span>
      </label>
    </div>
    <p v-if="text" class="switch-title">{{ text }}</p>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onMounted, watch } from 'vue'

export default {
  name: 'SwitchToggle',
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    onValueUpdate: {
      type: Function,
      default: null,
    },
    value: {
      type: Boolean,
    },
    bindValue: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const switchValue = ref(undefined)

    function setSwitchValue(value) {
      switchValue.value = value
    }

    function onSwitchInput(event) {
      if (props.onValueUpdate) {
        const newValueAttempt = event.target.checked
        if (props.bindValue) {
          //undo changing itself (must be changed by prop)
          event.target.checked = !newValueAttempt
        }
        props.onValueUpdate(newValueAttempt)
      }
    }

    // Get the initial value from the props.
    onMounted(() => {
      setSwitchValue(props.value)
    })

    // Watch for new values.
    watch(() => props.value, (newValue) => {
      setSwitchValue(newValue)
    })

    return {
      switchValue,
      setSwitchValue,
      onSwitchInput
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";

$switch-circle-color: white;
$switch-background-color: var(--purple_light);
$switch-on-background-color: var(--pink_main);

.switch-title {
  font-size: rem(16);
  letter-spacing: 0;
  line-height: rem(19);
  margin-left: rem(24);
}

.switch-box {
  align-items: center;
  display: flex;
}

/* The switch - the box around the slider */
.switch {
  border-radius: rem(18);
  display: inline-block;
  height: rem(25);
  position: relative;
  width: rem(46);
  margin-right: rem(10)
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  @include position(absolute, $top: 0, $right: 0, $bottom: 0, $left: 0);
  @include hover-active-pointer();
  background-color: $switch-background-color;
  transition: .4s;
  -webkit-transition: .4s;
}

.slider:before {
  background-color: $switch-circle-color;
  bottom: rem(2);
  content: "";
  height: rem(21);
  left: rem(2);
  position: absolute;
  transition: .4s;
  width: rem(21);
  -webkit-transition: .4s;
}

input:checked + .slider {
  background-color: $switch-on-background-color;
}

input:checked + .slider:before {
  background-color: $switch-circle-color;
}

input:focus + .slider {
  /*box-shadow: 0 0 1px #2196F3;*/
}

input:checked + .slider:before {
  -webkit-transform: translateX(rem(21));
  -ms-transform: translateX(rem(21));
  transform: translateX(rem(21));
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (min-width: $screen-lg) {
  .switch-toggle {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: $screen-xxl) {
  /* The switch - the box around the slider */
  .switch {
    border-radius: 12px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    display: inline-block;
    height: 1.5em;
    position: relative;
    width: 3em;
    margin-right: 10px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1.5em;
    width: 1.5em;
    left: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  }

  input:checked + .slider {
    background-color: $switch-on-background-color;
  }

  input:focus + .slider {
    /*box-shadow: 0 0 1px #2196F3;*/
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(1.625em);
    -ms-transform: translateX(1.625em);
    transform: translateX(1.625em);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

@media screen and (min-width: $screen-tv) {
  .switch-title {
    font-size: 0.75vw;
  }
}
</style>
