

import { ROUTE_NAMES_TEACHER_PORTAL } from '@/router/modules/teacherPortal'
import { ROUTE_NAMES_PROFESSIONAL } from '@/router/modules/professional'
import LocalDataHandler from '@/utils/handlers/LocalDataHandler'
import { USER_TYPES } from '@/models/User'

export const PAGES = {
  HELP: 'help',
  INSTRUCTIONS: 'Instructions',
  EDIT_DETAILS: 'EditDetails',
  SETTINGS: 'Settings',
}


export function getCorrectLink(location) {
  const user = LocalDataHandler.getUser()
  const professional = user && user.type === USER_TYPES.PROFESSIONAL
  switch (location) {
    case PAGES.HELP:
      return professional ? ROUTE_NAMES_PROFESSIONAL.HELP : ROUTE_NAMES_TEACHER_PORTAL.HELP
    case PAGES.INSTRUCTIONS:
      return professional ? ROUTE_NAMES_PROFESSIONAL.INSTRUCTIONS : ROUTE_NAMES_TEACHER_PORTAL.INSTRUCTIONS
    case PAGES.EDIT_DETAILS:
      return professional ? ROUTE_NAMES_PROFESSIONAL.EDIT_DETAILS : ROUTE_NAMES_TEACHER_PORTAL.EDIT_DETAILS
    case PAGES.SETTINGS:
      return professional ? ROUTE_NAMES_PROFESSIONAL.SETTINGS : ROUTE_NAMES_TEACHER_PORTAL.SETTINGS
  }
}